import { Avatar } from '@mantine/core';
import toast, { Toast, ToastOptions } from 'react-hot-toast';
import { BsCheckCircleFill } from 'react-icons/bs';
import { MdError } from 'react-icons/md';
// import { Link } from "react-router-dom";
import { HashLink as Link } from 'react-router-hash-link';
import Xero from '../icons/Xero';
import loading from '@assets/loading.svg';
import { Info } from '@phosphor-icons/react';
import { modals } from '@mantine/modals';
import type { MentionSocketData, OnlineUser } from 'beneluxic-types';
import React from 'react';
import RichTextViewer from '@components/RichTextViewer';
import { Button } from '@components/Button';
import ChatPreview from '@components/ChatPreview';

export default class customToast {
  id: string | undefined;
  constructor() {
    this.id = undefined;
  }

  start(message: string, config?: ToastOptions) {
    toast.dismiss(this.id);
    this.id = toast.custom(
      (t) => {
        return <LoadingComponent message={message} t={t} />;
      },
      {
        duration: Infinity,
        ...config,
      }
    );
  }

  success(message: string, config?: ToastOptions) {
    toast.custom(
      (t) => {
        return <SuccessComponent message={message} t={t} />;
      },
      {
        id: this.id,
        duration: config?.duration ? config.duration : 500,
        ...config,
      }
    );
  }

  info(message: string, config?: ToastOptions) {
    toast.custom(
      (t) => {
        return <InfoComponent message={message} t={t} />;
      },
      {
        id: this.id,
        duration: config?.duration ? config.duration : 4000,
        ...config,
      }
    );
  }

  error(message: string, config?: ToastOptions) {
    toast.custom(
      (t) => {
        return <ErrorComponent message={message} t={t} />;
      },
      {
        id: this.id,
        duration: config?.duration ? config.duration : 4000,
        ...config,
      }
    );
  }

  custom(element: React.JSX.Element, config?: ToastOptions) {
    toast.custom(element, {
      id: this.id,
      ...config,
    });
  }

  notifyWithUser(
    { message, user, url, hash }: MentionSocketData,
    config?: ToastOptions
  ) {
    toast.custom(
      (t) => {
        return (
          <NotificationComponent
            message={message}
            t={t}
            user={user}
            url={url}
            hash={hash}
          />
        );
      },
      {
        id: this.id,
        duration: config?.duration ? config.duration : 120000,
        position: 'bottom-right',
        ...config,
      }
    );
  }

  errorWithLink(
    message: string,
    url: string,
    urlMessage: string,
    config?: ToastOptions
  ) {
    toast.custom(
      (t) => {
        return (
          <ErrorComponent
            message={message}
            t={t}
            url={url}
            urlMessage={urlMessage}
          />
        );
      },
      {
        id: this.id,
        duration: config?.duration ? config.duration : 4000,
        ...config,
      }
    );
  }

  errorWithModal(
    message: string,
    linkText: string,
    modalProps: any,
    onClose: () => void,
    config?: ToastOptions
  ) {
    toast.custom(
      (t) => {
        return (
          <ErrorComponentModal
            message={message}
            t={t}
            linkText={linkText}
            modalProps={modalProps}
            onClose={onClose}
          />
        );
      },
      {
        id: this.id,
        duration: config?.duration ? config.duration : 4000,
        ...config,
      }
    );
  }

  errorXero(error: any, setHistory?: () => void, config?: ToastOptions) {
    toast.custom(
      (t) => {
        return (
          <XeroErrorComponent error={error} setHistory={setHistory} t={t} />
        );
      },
      {
        id: this.id,
        duration: config?.duration ? config.duration : 6000,
        ...config,
      }
    );
  }

  dismiss() {
    toast.dismiss(this.id);
  }

  remove() {
    toast.remove(this.id);
  }
}

const ErrorComponent = ({
  message,
  t,
  url,
  urlMessage,
}: {
  message: string;
  t: Toast;
  url?: string;
  urlMessage?: string;
}) => {
  return (
    <div
      className={`${
        t.visible ? 'fade-in-slow' : 'slide-out'
      } pointer-events-auto flex w-full max-w-md rounded-lg bg-white shadow-lg ring-1 ring-black ring-opacity-5 dark:bg-gray-base`}
    >
      <div className="w-0 flex-1 p-4">
        <div className="flex items-start">
          <div className="my-auto flex-shrink-0 items-center pt-0.5">
            <MdError className="text-red-500" size={24} />
          </div>
          <div className="ml-3 flex-1">
            <p className="text-sm font-medium text-red-500 ">Error</p>
            <p className="mt-1 text-sm text-gray-500 dark:text-slate-50">
              {message}
            </p>
            {url && urlMessage && (
              <Link
                className="text-sm text-custom-purple underline"
                to={url}
                onClick={() => {
                  toast.dismiss(t.id);
                }}
              >
                {urlMessage}
              </Link>
            )}
          </div>
        </div>
      </div>
      <div className="flex border-l border-gray-200 dark:border-gray-1">
        <button
          onClick={() => toast.remove(t.id)}
          className="flex w-full items-center justify-center rounded-none rounded-r-lg border border-transparent p-4 text-sm font-medium text-gray-600 hover:text-red-500 focus:outline-none dark:text-slate-200"
        >
          Close
        </button>
      </div>
    </div>
  );
};

const ErrorComponentModal = ({
  message,
  t,
  linkText,
  modalProps,
  onClose,
}: {
  message: string;
  t: Toast;
  linkText: string;
  modalProps: any;
  onClose: () => void;
}) => {
  return (
    <div
      className={`${
        t.visible ? 'fade-in-slow' : 'slide-out'
      } pointer-events-auto flex w-full max-w-md rounded-lg bg-white shadow-lg ring-1 ring-black ring-opacity-5 dark:bg-gray-base`}
    >
      <div className="w-0 flex-1 p-4">
        <div className="flex items-start">
          <div className="my-auto flex-shrink-0 items-center pt-0.5">
            <MdError className="text-red-500" size={24} />
          </div>
          <div className="ml-3 flex-1">
            <p className="text-sm font-medium text-red-500 ">Error</p>
            <p className="mt-1 text-sm text-gray-500 dark:text-slate-50">
              {message}
            </p>
            <button
              className="text-sm text-custom-purple underline"
              onClick={() => {
                modals.openContextModal({
                  modal: 'editCustomer',
                  title: (
                    <div className="font-heading font-bold">{message}</div>
                  ),
                  size: 'xl',
                  classNames: {
                    content: 'scroll-bar-thin',
                  },
                  closeOnClickOutside: false,
                  overlayProps: { blur: 2 },
                  centered: true,
                  innerProps: {
                    ...modalProps,
                  },
                  onClose,
                });
                toast.dismiss(t.id);
              }}
            >
              {linkText}
            </button>
          </div>
        </div>
      </div>
      <div className="flex border-l border-gray-200 dark:border-gray-1">
        <button
          onClick={() => toast.remove(t.id)}
          className="flex w-full items-center justify-center rounded-none rounded-r-lg border border-transparent p-4 text-sm font-medium text-gray-600 hover:text-red-500 focus:outline-none dark:text-slate-200"
        >
          Close
        </button>
      </div>
    </div>
  );
};

const InfoComponent = ({ message, t }: { message: string; t: Toast }) => {
  return (
    <div
      className={`${
        t.visible ? 'fade-in-slow' : 'slide-out'
      } pointer-events-auto flex w-full max-w-md rounded-lg bg-white shadow-lg ring-1 ring-black ring-opacity-5 dark:bg-gray-base`}
    >
      <div className="w-0 flex-1 p-4">
        <div className="flex items-start">
          <div className="my-auto flex-shrink-0 items-center pt-0.5">
            <Info className="text-purple-tint-4" size={24} />
          </div>
          <div className="ml-3 flex-1">
            <p className="text-sm font-medium text-purple-tint-4">Alert</p>
            <p className="mt-1 text-sm text-gray-500 dark:text-slate-50">
              {message}
            </p>
          </div>
        </div>
      </div>
      <div className="flex border-l border-gray-200 dark:border-gray-1">
        <button
          onClick={() => toast.remove(t.id)}
          className="flex w-full items-center justify-center rounded-none rounded-r-lg border border-transparent p-4 text-sm font-medium text-gray-600 hover:text-red-500 focus:outline-none dark:text-slate-200"
        >
          Close
        </button>
      </div>
    </div>
  );
};

const SuccessComponent = ({ message, t }: { message: string; t: Toast }) => {
  return (
    <div
      className={`${
        t.visible ? 'fade-in-slow' : 'slide-out'
      } pointer-events-auto flex w-full max-w-md rounded-lg bg-white shadow-lg ring-1 ring-black ring-opacity-5 dark:bg-gray-base`}
    >
      <div className="w-0 flex-1 p-4">
        <div className="flex items-start">
          <div className="my-auto flex-shrink-0 items-center pt-0.5">
            <BsCheckCircleFill className="text-green-500" size={24} />
          </div>
          <div className="ml-3 flex-1">
            <p className="text-sm font-medium text-green-500 ">Success</p>
            <p className="mt-1 text-sm text-gray-500 dark:text-slate-50">
              {message}
            </p>
          </div>
        </div>
      </div>
      <div className="flex border-l border-gray-200 dark:border-gray-1">
        <button
          onClick={() => toast.remove(t.id)}
          className="flex w-full items-center justify-center rounded-none rounded-r-lg border border-transparent p-4 text-sm font-medium text-gray-600 hover:text-red-500 focus:outline-none dark:text-slate-200"
        >
          Close
        </button>
      </div>
    </div>
  );
};
const LoadingComponent = ({ message, t }: { message: string; t: Toast }) => {
  return (
    <div
      className={`${
        t.visible ? 'fade-in-slow' : 'slide-out'
      } pointer-events-auto flex w-full max-w-md rounded-lg bg-white shadow-lg ring-1 ring-black ring-opacity-5 dark:bg-gray-base`}
    >
      <div className="w-0 flex-1 p-4">
        <div className="flex items-start">
          <div className="my-auto flex-shrink-0 items-center pt-0.5">
            <img
              src={loading}
              alt="loading"
              className="mr-2 inline h-8 w-8 animate-spin fill-current text-gray-200 dark:text-gray-600 dark:text-slate-200"
            />
          </div>
          <div className="ml-3 flex-1">
            <p className="text-sm font-medium text-gray-700 ">Loading</p>
            <p className="mt-1 text-sm text-gray-500 dark:text-slate-50">
              {message}
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};
const XeroErrorComponent = ({
  error,
  t,
  setHistory,
}: {
  error: any;
  t: Toast;
  setHistory: (() => void) | undefined;
}) => {
  return (
    <div className="flex rounded-md bg-white shadow-md dark:bg-gray-base">
      <div className="w-96 flex-col items-center justify-center p-10 ">
        <Xero size={30} className="mx-auto mb-5" />
        <span>{error?.response?.data?.message}</span>
        <div className="mt-5 flex items-center justify-center">
          <Button
            onClick={() => {
              setHistory && setHistory();
              window.open(error?.response?.data?.url, '_blank');
              toast.dismiss(t.id);
            }}
          >
            Authenticate
          </Button>
        </div>
      </div>
      <div className="flex border-l border-gray-200 dark:border-gray-1">
        <button
          onClick={() => toast.remove(t.id)}
          className="flex w-full items-center justify-center rounded-none rounded-r-lg border border-transparent p-4 text-sm font-medium text-gray-600 hover:text-red-500 focus:outline-none dark:text-slate-200 dark:hover:text-red-500"
        >
          Close
        </button>
      </div>
    </div>
  );
};
const NotificationComponent = ({
  message,
  t,
  user,
  url,
  hash,
}: {
  message: string;
  t: Toast;
  user: {
    firstName: string;
    lastName: string;
    fullName?: string;
    imageUrl: string;
    role: string;
  };
  url?: string;
  hash?: string;
}) => {
  return (
    <div
      className={`${
        t.visible ? 'fade-in-slow' : 'slide-out'
      } pointer-events-auto flex w-full max-w-md rounded-lg bg-white shadow-lg ring-1 ring-black ring-opacity-5 dark:bg-gray-base`}
    >
      <div className="w-0 flex-1 p-4">
        <div className="flex items-start">
          {user && (
            <div className="flex-shrink-0 items-center pt-0.5">
              <Avatar
                radius="xl"
                src={user?.imageUrl}
                alt={user.firstName}
                size="md"
                // color="purple"
              >
                {(
                  user.firstName?.charAt(0) + user.lastName?.charAt(0) || ''
                ).toUpperCase()}
              </Avatar>
            </div>
          )}
          <div className="ml-3 flex-1 overflow-hidden">
            <div className="flex items-start justify-between">
              <p className="text-sm font-medium text-custom-gold ">
                {user ? `${user.firstName} mentioned you` : 'Notification'}
              </p>
              {url && (
                <Link
                  smooth
                  to={{
                    pathname: url,
                    hash: hash,
                  }}
                  className="text-sm font-medium text-custom-gold hover:underline"
                  onClick={() => {
                    toast.dismiss(t.id);
                  }}
                >
                  Click here to view
                </Link>
              )}
            </div>
            <ChatPreview
              html={message}
              tag="span"
              className="block max-h-4 overflow-hidden truncate text-xs text-gray-500"
            />
          </div>
        </div>
      </div>
      <div className="flex border-l border-gray-200 dark:border-gray-1">
        <button
          onClick={() => toast.remove(t.id)}
          className="flex w-full items-center justify-center rounded-none rounded-r-lg border border-transparent p-4 text-sm font-medium text-gray-600 hover:text-red-500 focus:outline-none dark:text-slate-200"
        >
          Close
        </button>
      </div>
    </div>
  );
};

//TODO: make a success component, loading component, notifcation component
