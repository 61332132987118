import { Fragment, useEffect, useState } from 'react';
import { NavLink } from 'react-router-dom';
import {
  globalStore,
  userSettingsAtom,
  basketAtom,
  userAtom,
} from '../store/jotaiStore';
import {
  Basket,
  ChartLine,
  ChatCenteredDots,
  Database,
  DevToLogo,
  FileArrowUp,
  House,
  Kanban,
  Package,
  Receipt,
  Stack,
  UserList,
  Users,
} from '@phosphor-icons/react';
import { useAtomValue } from 'jotai';

export function Navbar() {
  const [userSettings, setUserSettings] = useState(
    globalStore.get(userSettingsAtom)
  );
  const unsub = globalStore.sub(userSettingsAtom, () => {
    setUserSettings(globalStore.get(userSettingsAtom));
  });
  const user = globalStore.get(userAtom);
  const items = useAtomValue(basketAtom);

  const navBarItems = [
    {
      to: '/',
      icon: <House size={20} className="inline" />,
      label: 'Dashboard',
    },
    {
      to: '/customers',
      icon: <Users size={20} className="inline" />,
      label: 'Customers',
    },
    {
      to: '/brands',
      icon: <Stack size={20} className="inline" />,
      label: 'Brands',
    },
    {
      to: '/orders',
      icon: <Receipt size={20} className="inline " />,
      label: 'Orders',
    },
    {
      to: '/products',
      icon: <Package size={20} className="inline" />,
      label: 'Products',
    },
    {
      to: '/reports',
      icon: <ChartLine size={20} className="inline" />,
      label: 'Reports',
    },
    {
      to: '/import',
      icon: <FileArrowUp size={20} className="inline" />,
      label: 'Import',
    },
    {
      to: '/datasets',
      icon: <Database size={20} className="inline" />,
      label: 'Dataset',
    },
    {
      to: '/incidents',
      icon: <Kanban size={20} className="inline" />,
      label: 'Incidents',
    },
    {
      to: '/messaging',
      dev: true,
      icon: <ChatCenteredDots size={20} className="inline" />,
      label: 'Messaging',
    },
    {
      to: '/devtools',
      dev: true,
      icon: <DevToLogo size={20} className="inline" />,
      label: 'Devtools',
    },
    {
      to: '/accounts',
      icon: <UserList size={20} className="inline" />,
      label: 'Accounts',
    },
    {
      to: '/basket',
      icon: <Basket size={20} className="inline" />,
      label: 'Basket',
    },
  ];

  useEffect(() => {
    return () => {
      unsub();
    };
  }, [unsub]);

  return (
    <div className={`z-50 hidden bg-gray-shade-1 dark:bg-gray-base md:block`}>
      <div className="mt-4 flex h-full flex-col gap-1 px-4">
        {userSettings?.nav === 'open' ? (
          <>
            {navBarItems.map((item) => (
              <Fragment key={item.label}>
                {item.dev && user?.role !== 'Developer' ? null : (
                  <NavLink
                    to={item.to}
                    className={({ isActive, isPending }) =>
                      isPending ? '' : isActive ? 'nav-item-active' : 'nav-item'
                    }
                  >
                    {item.icon}
                    {item.label === 'Basket' && items.length > 0 && (
                      <span className="absolute left-0 top-0 flex min-w-[16px] items-center justify-center rounded-full bg-red-500 px-1 text-xs text-white">
                        {items.length}
                      </span>
                    )}
                    <span className="animate-slide-out pl-3 align-middle">
                      {item.label}
                    </span>
                    <span className="sr-only">{item.label}</span>
                  </NavLink>
                )}
              </Fragment>
            ))}
          </>
        ) : (
          <>
            {navBarItems.map((item) => (
              <Fragment key={`${item.label}-small`}>
                {item.dev && user?.role !== 'Developer' ? null : (
                  <NavLink
                    to={item.to}
                    className={({ isActive, isPending }) =>
                      isPending
                        ? ''
                        : isActive
                          ? 'nav-item-active-small'
                          : 'nav-item group relative'
                    }
                  >
                    {item.icon}
                    {item.label === 'Basket' && items.length > 0 && (
                      <span className="absolute left-0 top-0 flex min-w-[16px] items-center justify-center rounded-full bg-red-500 px-1 text-xs text-white">
                        {items.length}
                      </span>
                    )}
                    <div className="nav-label">{item.label}</div>
                    <span className="sr-only">{item.label}</span>
                  </NavLink>
                )}
              </Fragment>
            ))}
          </>
        )}
      </div>
    </div>
  );
}
