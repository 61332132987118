import { parse } from 'qs';
import { useCallback, useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import PulseLoader from 'react-spinners/PulseLoader';
import customToast from '../../utils/toast';
import { apiClientAtom, globalStore } from '@store/jotaiStore';

function Xero() {
  const axios = globalStore.get(apiClientAtom).fn();
  const location = useLocation();
  const [loading, setLoading] = useState(false);
  const [query, setQuery] = useState({});

  useEffect(() => {
    setQuery(
      parse(location.search, {
        ignoreQueryPrefix: true,
      })
    );
  }, [location.search]);

  const checkAuth = useCallback(
    async (code, redirect_uri) => {
      const toast = new customToast();
      setLoading(true);
      await axios
        .post('/xero/authorise', {
          code,
          redirect_uri,
        })
        .then(() => {
          setLoading(false);
          setTimeout(() => {
            window.opener = null;
            window.open('', '_self');
            window.close();
          }, 3000);
        })
        .catch((err) => {
          setLoading(false);
          toast.error(err.response.data.message);
        });
    },
    [axios]
  );

  useEffect(() => {
    if (Object.keys(query).length) {
      const { code } = query;
      const fullUrl = window.location.href;
      checkAuth(code, fullUrl);
    }
  }, [query, checkAuth]);

  return (
    <div className="h-full overflow-y-hidden">
      {loading ? (
        <div className="my-auto flex h-full items-center justify-center">
          <PulseLoader size={50} color={'#c7d2fe '} loading={loading} />
        </div>
      ) : (
        <div className="flex h-full w-full flex-col items-center justify-center gap-6 text-center">
          <Xero size={100} className="animate-bounce" />
          <div className="text-4xl">Authentication successful!</div>
          <div> This window will close automatically. </div>
        </div>
      )}
    </div>
  );
}

export default Xero;
