import { User_Settings, UserWithIncluded } from 'beneluxic-types';
import { apiClientAtom, globalStore } from '../store/jotaiStore';
import { AccountDetails, AccountEdit, AccountNew } from '@validation/user';

function getClient() {
  return globalStore.get(apiClientAtom).fn();
}

export const UserServices = {
  getAllUsers: async (): Promise<UserWithIncluded[]> => {
    const axiosClient = getClient();
    return await axiosClient
      .get<UserWithIncluded[]>('/user')
      .then((res) => {
        return res.data;
      })
      .catch((error) => {
        throw error;
      });
  },
  getCsrfToken: async (): Promise<string> => {
    const axiosClient = getClient();
    const csrf = await axiosClient
      .get('auth/csrfToken')
      .then((res) => {
        return res.data;
      })
      .catch((error) => {
        throw error;
      });
    return csrf;
  },
  registerAccount: async (user: AccountNew) => {
    const _csrf = await UserServices.getCsrfToken();
    const axiosClient = getClient();
    await axiosClient
      .post('auth/register', {
        ...user,
        _csrf,
      })
      .then((res) => {
        return res.data;
      })
      .catch((err) => {
        throw err;
      });
  },
  updateUser: async (user: AccountEdit | AccountDetails) => {
    const _csrf = await UserServices.getCsrfToken();
    const axiosClient = getClient();
    await axiosClient
      .put(`auth/user/${user.id}`, {
        ...user,
        _csrf,
      })
      .then((res) => {
        return res.data;
      })
      .catch((err) => {
        throw err;
      });
  },
  disableUser: async (id: string) => {
    const _csrf = await UserServices.getCsrfToken();
    if (!_csrf) {
      throw new Error('CSRF token not found');
    }
    const body = {
      status: {
        disabled: true,
      },
      _csrf,
    };
    const axiosClient = getClient();
    await axiosClient
      .put(`auth/user/${id}`, body)
      .then((res) => {
        return res.data;
      })
      .catch((err) => {
        throw err;
      });
  },
  enableUser: async (id: string) => {
    const _csrf = await UserServices.getCsrfToken();
    const axiosClient = getClient();
    await axiosClient
      .put(`auth/user/${id}`, {
        status: {
          disabled: false,
        },
        _csrf,
      })
      .then((res) => {
        return res.data;
      })
      .catch((err) => {
        throw err;
      });
  },
  updateSettings: async (settings: Partial<User_Settings>) => {
    const _csrf = await UserServices.getCsrfToken();
    const axiosClient = getClient();
    await axiosClient
      .put(`user/settings`, {
        ...settings,
        _csrf,
      })
      .then((res) => {
        return res.data;
      })
      .catch((err) => {
        throw err;
      });
  },
};
