import { useEffect, useState } from 'react';
import { Howl, Howler } from 'howler';
import customToast from '../utils/toast';
import chime from '@audio/chime.wav';
import {
  activityAtom,
  lastActivityAtom,
  onlineUsersAtom,
  onlineUsersStore,
  socketAtom,
} from '../store/jotaiStore';
import { Socket, io } from 'socket.io-client';
import { useAtom } from 'jotai';
import { MentionSocketData, OnlineUser } from 'beneluxic-types';
import { AuthedUser } from 'beneluxic-types';
import { DefaultEventsMap } from '@socket.io/component-emitter';

Howler.autoUnlock = false;

const toast = new customToast();
const useSocketEventHandlers = (user: AuthedUser | null) => {
  const [socket, setSocket] = useAtom(socketAtom);
  const [activity, setActivity] = useState('online');
  onlineUsersStore.sub(activityAtom, () => {
    setActivity(onlineUsersStore.get(activityAtom));
  });
  const [lastActivity, setLastActivity] = useState(new Date().toISOString());
  onlineUsersStore.sub(lastActivityAtom, () => {
    setLastActivity(onlineUsersStore.get(lastActivityAtom));
  });

  useEffect(() => {
    if (!user) return;

    let currentSocket: Socket<DefaultEventsMap, DefaultEventsMap>; // Store the socket instance for cleanup

    const connectSocket = () => {
      currentSocket = io(
        import.meta.env.MODE === 'development'
          ? 'http://localhost:5000'
          : import.meta.env.VITE_WS,
        {
          query: {
            userId: user.id,
            name: user.firstName,
            status: activity,
            lastActivity: lastActivity,
          },
          withCredentials: true,
        }
      );

      setSocket(currentSocket);

      currentSocket.on('online_users', onlineUsers);
      currentSocket.on('user_status_update', onUserStatusUpdate);
      currentSocket.on('mentioned', onMention);
    };

    connectSocket(); // Establish initial connection

    return () => {
      toast.dismiss();
      try {
        if (currentSocket) {
          currentSocket.off('mentioned', (socket: MentionSocketData) =>
            onMention(socket)
          );
          currentSocket.off('user_status_update', onUserStatusUpdate);
          currentSocket.off('online_users', onlineUsers);
          currentSocket.disconnect();
        }
      } catch (error) {
        console.warn('Error disconnecting socket:', error);
      }
      setSocket(null); // Clear the atom for potential reconnections
    };
  }, [user]);
};

function onlineUsers(socketData: OnlineUser[]) {
  const onlineUsersMap = new Map();
  socketData.forEach((u) => {
    onlineUsersMap.set(u.id, u);
  });

  onlineUsersStore.set(onlineUsersAtom, () => onlineUsersMap);
}

function onUserStatusUpdate(socketData: OnlineUser) {
  const oua = onlineUsersStore.get(onlineUsersAtom);
  onlineUsersStore.set(onlineUsersAtom, () => {
    const onlineUsersMap = new Map(oua);
    const { id, status } = socketData;
    if (!id) return onlineUsersMap;
    const user = onlineUsersMap.get(id);
    if (user) {
      user.status = status;
    }
    return onlineUsersMap;
  });
}

function onMention(socketData: MentionSocketData) {
  // show the toast once
  toast.notifyWithUser(socketData);
  // Play the sound.
  const sound = new Howl({
    src: [chime],
    volume: 0.2,
  });
  sound.play();
}

export default useSocketEventHandlers;
