import { Navigate, Outlet, useNavigate } from 'react-router-dom';
import { globalStore, userAtom } from '../store/jotaiStore';
import { Provider } from 'jotai';
import DefaultFallback from '@components/Fallback';
import { ErrorBoundary } from 'react-error-boundary';
import { ErrorInfo, Suspense, useState } from 'react';
import LoadingSpinner from '@components/LoadingSpinner';
import { DevTools } from 'jotai-devtools';

export default function ProtectedRoute({
  store,
}: {
  store?: typeof globalStore;
}) {
  const [user, setUser] = useState(globalStore.get(userAtom));
  globalStore.sub(userAtom, () => {
    setUser(globalStore.get(userAtom));
  });
  const navigate = useNavigate();

  function onError(error: Error, info: ErrorInfo) {
    // log the error to your error reporting service
    console.log(error, info);
    console.log('ERROR: ', error);
    console.log('INFO: ', info);
  }

  return user ? (
    <Provider store={store}>
      {/* <DevTools /> */}
      <ErrorBoundary
        FallbackComponent={(fb) => (
          <DefaultFallback
            error={fb.error}
            resetErrorBoundary={fb.resetErrorBoundary}
            buttonText={'Back to Dashboard'}
          />
        )}
        onReset={() => navigate('/')}
        onError={onError}
      >
        <Suspense fallback={<LoadingSpinner />}>
          <Outlet />
        </Suspense>
      </ErrorBoundary>
    </Provider>
  ) : (
    <Navigate
      to="/login"
      state={{
        from: window.location.pathname + window.location.search,
      }}
    />
  );
}
