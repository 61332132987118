import parse, { Element } from 'html-react-parser';
import React from 'react';

export default function ChatPreview({
  html,
  tag = 'div',
  ...props
}: {
  html: string;
  tag?: string;
  [key: string]: any;
}) {
  const replaceableTypes = {
    product: 'name',
    order: 'nanoId',
    customer: 'name',
    user: 'name',
  };
  const Component: React.ElementType = tag as React.ElementType;
  return (
    <Component suppressContentEditableWarning={true} {...props}>
      {parse(html, {
        replace: (domNode) => {
          if (domNode instanceof Element && domNode.attribs) {
            const { attribs } = domNode;
            if (!attribs) {
              return;
            }

            if (
              Object.keys(replaceableTypes).includes(attribs.type as string) &&
              attribs.id
            ) {
              // return the text only
              const type = attribs.type as keyof typeof replaceableTypes;
              const text = attribs[replaceableTypes[type]];
              return <span>{text}</span>;
            }

            return domNode;
          }
        },
      })}
    </Component>
  );
}
