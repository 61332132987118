import { Avatar, AvatarProps, Indicator } from '@mantine/core';
import { getColorFromStatus } from '../utils/helperFunctions';
import { Skull } from '@phosphor-icons/react';

function StatusAvatar({
  name,
  imageUrl,
  activity,
  indicatorProps = {
    inline: true,
    size: 12,
    offset: 4,
    position: 'top-start',
    withBorder: true,
  },
  avatarProps = {
    size: 'md',
    radius: 'md',
    color: 'violet',
  },
}: {
  name?: string | null;
  imageUrl?: string | null;
  activity?: string;
  indicatorProps?: any;
  avatarProps?: AvatarProps;
}) {
  const initials = name
    ?.split(' ')
    ?.map((n) => n[0])
    ?.join('');
  return (
    <Indicator color={getColorFromStatus(activity)} {...indicatorProps}>
      <Avatar src={imageUrl} alt={name ? name : 'Deleted'} {...avatarProps}>
        {name ? initials : <Skull size={32} />}
      </Avatar>
    </Indicator>
  );
}

export default StatusAvatar;
