import ProtectedRoute from '@utils/ProtectedRoute';
import { Suspense, lazy, useEffect } from 'react';
import { Outlet, RouterProvider, createBrowserRouter } from 'react-router-dom';
import LoadingSpinner from '@components/LoadingSpinner';
import Wrapper from './layout/Wrapper';
import {
  onlineUsersStore,
  basketStore,
  basketAtom,
  globalStore,
} from './store/jotaiStore';
import Xero from './pages/Xero';
import { Howl } from 'howler';
import chime from '@audio/chime.wav';
import RouteFallback from '@components/RouteFallback';

const sound = new Howl({
  src: [chime],
  volume: 0,
});

const Customers = lazy(() => import('@pages/Customers'));
const Brands = lazy(() => import('@pages/Brands'));
const Orders = lazy(() => import('@pages/Orders'));
const Products = lazy(() => import('@pages/Products'));
const Reports = lazy(() => import('@pages/Reports'));
const Incidents = lazy(() => import('@pages/Incidents'));
const Basket = lazy(() => import('@pages/Basket'));
const Accounts = lazy(() => import('@pages/Accounts'));
const Customer = lazy(() => import('@pages/Customers/Customer'));
const Datasets = lazy(() => import('@pages/Dataset'));
const FourOhFour = lazy(() => import('@pages/FourOhFour'));
const Import = lazy(() => import('@pages/Import'));
const Login = lazy(() => import('@pages/Login'));
const Order = lazy(() => import('@pages/Orders/Order'));
const PasswordReset = lazy(() => import('@pages/PasswordReset'));
const Product = lazy(() => import('@pages/Products/Product'));
const Settings = lazy(() => import('@pages/Settings'));
const Brand = lazy(() => import('@pages/Brands/Brand'));
const Messaging = lazy(() => import('@pages/Messaging'));
const Dashboard = lazy(() => import('@pages/Dashboard'));
const Devtools = lazy(() => import('@pages/Devtools'));

function App() {
  const basket = basketStore.get(basketAtom);

  const warning = (e: BeforeUnloadEvent) => {
    e.preventDefault();
    return (e.returnValue = 'Are you sure you want to leave this page?');
  };

  // TODO: create hook for unload warning
  useEffect(() => {
    if (basket.length > 0 && import.meta.env.PROD) {
      window.addEventListener('beforeunload', warning);
    } else {
      window.removeEventListener('beforeunload', warning);
    }
    return () => {
      window.removeEventListener('beforeunload', warning);
    };
  }, [basket]);

  useEffect(() => {
    const handleAudioContext = () => {
      if (sound.state() === 'unloaded') {
        sound.load();
      }
      sound.play();
    };
    document.addEventListener('click', handleAudioContext);
    return () => {
      document.removeEventListener('click', handleAudioContext);
    };
  }, []);

  const router = createBrowserRouter([
    {
      path: '/',
      element: <Wrapper />,
      handle: () => 'Dashboard',
      errorElement: <RouteFallback />,
      children: [
        {
          element: <ProtectedRoute store={basketStore} />,
          children: [
            {
              path: '/customers',
              handle: () => 'Customers',
              children: [
                { element: <Customers />, index: true },
                { path: '/customers/:id', element: <Customer /> },
                { path: '/customers/:id/:tab', element: <Customer /> },
              ],
            },
            {
              path: '/brands',
              handle: () => 'Brands',
              children: [
                { element: <Brands />, index: true },
                { path: '/brands/:id', element: <Brand /> },
                { path: '/brands/:id/:tab', element: <Brand /> },
              ],
            },
            {
              path: '/orders',
              handle: () => 'Orders',
              children: [
                { element: <Orders />, index: true },
                { path: '/orders/:id', element: <Order /> },
                { path: '/orders/:id/:tab', element: <Order /> },
              ],
            },
            {
              path: '/products',
              handle: () => 'Products',
              children: [
                { element: <Products />, index: true },
                { path: '/products/:id', element: <Product /> },
                { path: '/products/:id/:tab', element: <Product /> },
              ],
            },
            {
              path: '/basket',
              handle: () => 'Basket',
              children: [{ element: <Basket />, index: true }],
            },
            {
              path: '/datasets',
              handle: () => 'Datasets',
              children: [
                { element: <Datasets />, index: true },
                { path: '/datasets/:tab', element: <Datasets /> },
              ],
            },
          ],
        },
        {
          element: <ProtectedRoute store={globalStore} />,
          children: [
            { element: <Dashboard />, index: true },
            {
              path: '/reports',
              handle: () => 'Reports',
              children: [{ element: <Reports />, index: true }],
            },
            {
              path: '/incidents',
              handle: () => 'Incidents',
              children: [{ element: <Incidents />, index: true }],
            },
            {
              path: '/accounts',
              handle: () => 'Accounts',
              children: [{ element: <Accounts />, index: true }],
            },
            {
              path: '/import',
              handle: () => 'Import',
              children: [{ element: <Import />, index: true }],
            },
            {
              path: '/settings',
              handle: () => 'Settings',
              children: [{ element: <Settings />, index: true }],
            },
            {
              path: '/xero',
              handle: () => 'Xero',
              children: [{ element: <Xero />, index: true }],
            },
            {
              path: '/devtools',
              handle: () => 'Devtools',
              children: [{ element: <Devtools />, index: true }],
            },
          ],
        },
        {
          element: <ProtectedRoute store={onlineUsersStore} />,
          children: [
            {
              path: '/messaging',
              handle: () => 'Messaging',
              children: [{ element: <Messaging />, index: true }],
            },
          ],
        },
        {
          element: (
            <Suspense fallback={<LoadingSpinner title="Loading the Hub..." />}>
              <Outlet />
            </Suspense>
          ),
          children: [
            { path: '/login', element: <Login /> },
            { path: '/password-reset', element: <PasswordReset /> },
            { path: '*', element: <FourOhFour /> },
          ],
        },
        {
          errorElement: <RouteFallback />,
        },
      ],
    },
  ]);

  return (
    <RouterProvider router={router} fallbackElement={<LoadingSpinner />} />
  );
}
export default App;
