import {
  apiClientAtom,
  globalStore,
  userSettingsAtom,
  socketAtom,
  userAtom,
} from '../store/jotaiStore';

type ResetPassword = {
  user: string;
  token: string;
  password: string;
  confirm: string;
};

type Credentials = {
  email: string;
  password: string;
};

function getClient() {
  return globalStore.get(apiClientAtom).fn();
}

// NOTE: this is a service for the auth routes, not the user routes,
// which are in userServices.ts no access to Bearer token here
const AuthServices = {
  getCsrfToken: async () => {
    const axiosClient = getClient();
    return await axiosClient
      .get('auth/csrfToken')
      .then((res) => {
        return res.data;
      })
      .catch((error) => {
        throw error;
      });
  },
  login: async (credentials: Credentials) => {
    const _csrf = await AuthServices.getCsrfToken();
    const axiosClient = getClient();
    const user = await axiosClient
      .post('auth/login', {
        ...credentials,
        _csrf,
      })
      .then((res) => {
        const { data } = res;
        globalStore.set(userAtom, data);
        if (data.settings) globalStore.set(userSettingsAtom, data.settings);
        const updatedClient = globalStore.get(apiClientAtom).fn();
        updatedClient.defaults.headers.Authorization = `Bearer ${data.accessToken}`;
        globalStore.set(apiClientAtom, { fn: () => updatedClient });
        return data;
      })
      .catch((error) => {
        throw error;
      });
    return user;
  },
  forgotPassword: async (email: string) => {
    const _csrf = await AuthServices.getCsrfToken();
    const axiosClient = getClient();
    await axiosClient
      .post('auth/forgotPassword', {
        email,
        _csrf,
      })
      .then((res) => {
        return res.data;
      })
      .catch((err) => {
        throw err;
      });
  },
  resetPassword: async (params: ResetPassword) => {
    const axiosClient = getClient();
    const _csrf = await AuthServices.getCsrfToken();
    await axiosClient
      .post('auth/passwordReset', {
        ...params,
        _csrf,
      })
      .then((res) => {
        return res.data;
      })
      .catch((err) => {
        throw err;
      });
  },
  refreshUser: async () => {
    const _csrf = await AuthServices.getCsrfToken();
    const axiosClient = getClient();
    const refreshedUser = await axiosClient
      .post('auth/refresh', {
        _csrf,
      })
      .then((res) => {
        globalStore.set(userAtom, res.data);
        if (res.data.settings)
          globalStore.set(userSettingsAtom, res.data.settings);
        const updatedClient = globalStore.get(apiClientAtom).fn();
        updatedClient.defaults.headers.Authorization = `Bearer ${res.data.accessToken}`;
        globalStore.set(apiClientAtom, { fn: () => updatedClient });
        return res.data;
      })
      .catch((err) => {
        throw err;
      });
    return refreshedUser;
  },
  logout: async () => {
    const _csrf = await AuthServices.getCsrfToken();
    const axiosClient = getClient();
    await axiosClient
      .post('auth/revoke', {
        _csrf,
      })
      .then(async () => {
        globalStore.set(userAtom, null);
        // globalStore.set(userSettingsAtom, RESET);
        const updatedClient = globalStore.get(apiClientAtom).fn();
        updatedClient.defaults.headers.Authorization = null;
        globalStore.set(apiClientAtom, { fn: () => updatedClient });

        // check for any socket connections and disconnect them
        const socket = globalStore.get(socketAtom);
        if (socket) {
          socket.disconnect();
          globalStore.set(socketAtom, null);
        }
      })
      .catch((error) => {
        throw error;
      });
  },
};

export default AuthServices;
