import { useAtom, useAtomValue } from 'jotai';
import {
  activityAtom,
  globalStore,
  onlineUsersStore,
  userAtom,
  userSettingsAtom,
} from '../store/jotaiStore';
import logo from '../assets/beneluxic-black.png';
import { useState } from 'react';
import StatusAvatar from '../components/StatusAvatar';
import * as DropdownMenu from '@radix-ui/react-dropdown-menu';
import {
  Bell,
  Gear,
  List,
  Moon,
  Sidebar,
  SidebarSimple,
  SignOut,
  SunDim,
} from '@phosphor-icons/react';
import { Avatar, Indicator, useMantineColorScheme } from '@mantine/core';
import { Link } from 'react-router-dom';
import { UserServices } from '@services/userServices';
import type { Settings_NavType } from 'beneluxic-types';
import AuthServices from '@services/authServices';

function Header() {
  const [userSettings, setUserSettings] = useState(
    globalStore.get(userSettingsAtom)
  );
  const [activity, setActivity] = useState('online');
  onlineUsersStore.sub(activityAtom, () => {
    setActivity(onlineUsersStore.get(activityAtom));
  });
  globalStore.sub(userSettingsAtom, () => {
    setUserSettings(globalStore.get(userSettingsAtom));
  });
  const user = useAtomValue(userAtom);
  const { setColorScheme } = useMantineColorScheme();
  const toggleDarkMode = () => {
    UserServices.updateSettings({
      ...globalStore.get(userSettingsAtom),
      darkMode: !globalStore.get(userSettingsAtom).darkMode,
    });
    globalStore.set(userSettingsAtom, {
      ...globalStore.get(userSettingsAtom),
      darkMode: !globalStore.get(userSettingsAtom).darkMode,
    });
    setColorScheme(
      globalStore.get(userSettingsAtom).darkMode === true ? 'dark' : 'light'
    );
  };

  const updateNav = (nav: Settings_NavType) => {
    UserServices.updateSettings({ ...userSettings, nav });
    globalStore.set(userSettingsAtom, { ...userSettings, nav });
  };

  const handleLogout = async () => {
    await AuthServices.logout();
  };

  return (
    <nav className="flex-grow-0 bg-gray-shade-1 dark:border-gray-shade-9 dark:bg-gray-base">
      <div className="px-4 lg:pr-8">
        <div className="flex h-12 items-center justify-between">
          <div className="flex items-center">
            <div className="hidden md:block">
              {userSettings?.nav === 'open' ? (
                <button
                  title="Menu toggle"
                  onClick={() => updateNav('closed')}
                  className="ring-none group relative mr-3 flex h-8 w-8 items-center justify-center rounded-md border-2 border-gray-shade-4 bg-gray-shade-1 p-1 text-gray-400 hover:text-white focus:outline-none dark:border-none dark:bg-gray-1"
                >
                  <span className="sr-only">Menu toggle</span>
                  <SidebarSimple className="h-6 w-6 group-hover:text-white" />
                </button>
              ) : (
                <button
                  title="Menu toggle"
                  onClick={() => updateNav('open')}
                  className="ring-none group relative mr-3 flex h-8 w-8 items-center justify-center rounded-md border-2 border-gray-shade-4 bg-gray-shade-1 p-1 text-gray-400 hover:text-white focus:outline-none dark:border-none dark:bg-gray-1"
                >
                  <span className="sr-only">Menu toggle</span>
                  <Sidebar className="h-6 w-6 group-hover:text-white" />
                </button>
              )}
            </div>
            <div className="flex-shrink-0">
              <img
                className="h-6 w-auto invert-0 filter dark:invert"
                src={logo}
                alt="Your Company"
              />
            </div>
            <div className="hidden md:block">{/* SearchBar in here */}</div>
          </div>
          <div className="hidden md:block">
            <div className="ml-4 flex items-center md:ml-6">
              <button
                title="Color scheme"
                onClick={toggleDarkMode}
                className="ring-none relative h-8 w-8 rounded-md bg-gray-shade-4 p-1 text-gray-400 hover:text-white focus:outline-none dark:bg-gray-1"
              >
                <span className="sr-only">Color scheme</span>
                {userSettings?.darkMode ? (
                  <SunDim className="h-6 w-6" />
                ) : (
                  <Moon className="h-6 w-6" />
                )}
              </button>
              <Indicator offset={4} color="purple" processing disabled={false}>
                <button
                  title="View notifications"
                  className="ring-none relative ml-3 h-8 w-8 rounded-md bg-gray-shade-4 p-1 text-gray-400 hover:text-white focus:outline-none dark:bg-gray-1"
                >
                  <span className="sr-only">View notifications</span>
                  <Bell className="h-6 w-6" />
                </button>
              </Indicator>

              <div className="relative ml-3">
                <div>
                  <DropdownMenu.Root>
                    <DropdownMenu.Trigger asChild>
                      <button
                        type="button"
                        className="ring-none relative flex max-w-xs items-center rounded-md bg-gray-shade-4 text-sm focus:outline-none dark:bg-gray-1"
                        id="user-menu-button"
                        aria-expanded="false"
                        aria-haspopup="true"
                      >
                        <span className="sr-only">Open user menu</span>
                        <Avatar
                          src={user?.imageUrl}
                          alt={user?.fullName!}
                          unstyled
                          classNames={{
                            root: 'h-8 w-8 items-center justify-center flex',
                            placeholder:
                              'text-gray-400 font-semibold hover:text-white',
                          }}
                        >
                          {user?.fullName
                            ?.split(' ')
                            ?.map((n) => n[0])
                            ?.join('')}
                        </Avatar>
                      </button>
                    </DropdownMenu.Trigger>
                    <DropdownMenu.Portal>
                      <DropdownMenu.Content
                        align="end"
                        sideOffset={8}
                        className="dropdown-content"
                      >
                        <DropdownMenu.Item asChild>
                          <Link to="/settings" className="context-menu-item">
                            <Gear className="mr-2 h-3.5 w-3.5" />
                            Settings
                          </Link>
                        </DropdownMenu.Item>
                        <DropdownMenu.Item
                          asChild
                          className="context-menu-item"
                        >
                          <button onClick={handleLogout}>
                            <SignOut className="mr-2 h-3.5 w-3.5" />
                            Sign out
                          </button>
                        </DropdownMenu.Item>
                      </DropdownMenu.Content>
                    </DropdownMenu.Portal>
                  </DropdownMenu.Root>
                </div>
              </div>
            </div>
          </div>
          <div className="-mr-2 flex md:hidden">
            <button
              type="button"
              className="relative inline-flex items-center justify-center rounded-md bg-gray-800 p-2 text-gray-400 hover:bg-gray-700 hover:text-white focus:outline-none focus:ring-2 focus:ring-white focus:ring-offset-2 focus:ring-offset-gray-800"
              aria-controls="mobile-menu"
              aria-expanded="false"
            >
              <span className="absolute -inset-0.5"></span>
              <span className="sr-only">Open main menu</span>
              <svg
                className="block h-6 w-6"
                fill="none"
                viewBox="0 0 24 24"
                strokeWidth="1.5"
                stroke="currentColor"
                aria-hidden="true"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M3.75 6.75h16.5M3.75 12h16.5m-16.5 5.25h16.5"
                />
              </svg>
              <svg
                className="hidden h-6 w-6"
                fill="none"
                viewBox="0 0 24 24"
                strokeWidth="1.5"
                stroke="currentColor"
                aria-hidden="true"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M6 18L18 6M6 6l12 12"
                />
              </svg>
            </button>
          </div>
        </div>
      </div>

      <div className="md:hidden" id="mobile-menu">
        <div className="space-y-1 px-2 pb-3 pt-2 sm:px-3">
          <a
            href="#"
            className="block rounded-md bg-gray-900 px-3 py-2 text-base font-medium text-white"
            aria-current="page"
          >
            Dashboard
          </a>
          <a
            href="#"
            className="block rounded-md px-3 py-2 text-base font-medium text-gray-300 hover:bg-gray-700 hover:text-white"
          >
            Customers
          </a>
          <a
            href="#"
            className="block rounded-md px-3 py-2 text-base font-medium text-gray-300 hover:bg-gray-700 hover:text-white"
          >
            Brands
          </a>
          <a
            href="#"
            className="block rounded-md px-3 py-2 text-base font-medium text-gray-300 hover:bg-gray-700 hover:text-white"
          >
            Orders
          </a>
          <a
            href="#"
            className="block rounded-md px-3 py-2 text-base font-medium text-gray-300 hover:bg-gray-700 hover:text-white"
          >
            Products
          </a>
          <a
            href="#"
            className="block rounded-md px-3 py-2 text-base font-medium text-gray-300 hover:bg-gray-700 hover:text-white"
          >
            Reports
          </a>
          <a
            href="#"
            className="block rounded-md px-3 py-2 text-base font-medium text-gray-300 hover:bg-gray-700 hover:text-white"
          >
            Import
          </a>
          <a
            href="#"
            className="block rounded-md px-3 py-2 text-base font-medium text-gray-300 hover:bg-gray-700 hover:text-white"
          >
            Datasets
          </a>
          <a
            href="#"
            className="block rounded-md px-3 py-2 text-base font-medium text-gray-300 hover:bg-gray-700 hover:text-white"
          >
            Incidents
          </a>
          <a
            href="#"
            className="block rounded-md px-3 py-2 text-base font-medium text-gray-300 hover:bg-gray-700 hover:text-white"
          >
            Messages
          </a>
        </div>
        <div className="border-t border-gray-700 pb-3 pt-4">
          <div className="flex items-center px-5">
            <div className="flex-shrink-0">
              <StatusAvatar
                activity={activity}
                imageUrl={user?.imageUrl}
                name={user?.fullName}
              />
            </div>
            <div className="ml-3">
              <div className="text-base font-medium leading-none text-white">
                {user?.fullName}
              </div>
              <div className="text-sm font-medium leading-none text-gray-400">
                {user?.email}
              </div>
            </div>
            <button
              type="button"
              className="relative ml-auto flex-shrink-0 rounded-full bg-gray-800 p-1 text-gray-400 hover:text-white focus:outline-none focus:ring-2 focus:ring-white focus:ring-offset-2 focus:ring-offset-gray-800"
            >
              <span className="absolute -inset-1.5"></span>
              <span className="sr-only">View notifications</span>
              <svg
                className="h-6 w-6"
                fill="none"
                viewBox="0 0 24 24"
                strokeWidth="1.5"
                stroke="currentColor"
                aria-hidden="true"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M14.857 17.082a23.848 23.848 0 005.454-1.31A8.967 8.967 0 0118 9.75v-.7V9A6 6 0 006 9v.75a8.967 8.967 0 01-2.312 6.022c1.733.64 3.56 1.085 5.455 1.31m5.714 0a24.255 24.255 0 01-5.714 0m5.714 0a3 3 0 11-5.714 0"
                />
              </svg>
            </button>
          </div>
          <div className="mt-3 space-y-1 px-2">
            <a
              href="#"
              className="block rounded-md px-3 py-2 text-base font-medium text-gray-400 hover:bg-gray-700 hover:text-white"
            >
              Your Profile
            </a>
            <a
              href="#"
              className="block rounded-md px-3 py-2 text-base font-medium text-gray-400 hover:bg-gray-700 hover:text-white"
            >
              Settings
            </a>
            <a
              href="#"
              className="block rounded-md px-3 py-2 text-base font-medium text-gray-400 hover:bg-gray-700 hover:text-white"
            >
              Sign out
            </a>
          </div>
        </div>
      </div>
    </nav>
  );
}

export default Header;
