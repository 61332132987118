// jotai global state store
import axios from 'axios';
import { atom, createStore } from 'jotai';
import { RESET, atomWithStorage } from 'jotai/utils';
import { Socket } from 'socket.io-client';
import {
  OnlineStatus,
  OnlineUser,
  AuthedUser,
  ProductWithIncluded,
  CustomerWithIncluded,
  OrderWithIncluded,
  User_Settings,
} from 'beneluxic-types';
import AuthServices from '@services/authServices';
import { redirect } from 'react-router-dom';

export type BasketItem = Partial<ProductWithIncluded> & { quantity: number };
export type Basket = BasketItem[];
export type OrderCustomer = OrderWithIncluded['customer'];
export type OnlineUsers = Map<string, OnlineUser>;

// Create a store

export const globalStore = createStore();
export const basketStore = createStore();
export const onlineUsersStore = createStore();

// User atoms for global state
export const userAtom = atom<AuthedUser | null>(null);
userAtom.debugLabel = 'userAtom';
export const activityAtom = atomWithStorage<OnlineStatus>('status', 'online');
activityAtom.debugLabel = 'activityAtom';
export const lastActivityAtom = atomWithStorage<string>(
  'lastActive',
  new Date().toISOString()
);
lastActivityAtom.debugLabel = 'lastActivityAtom';
// online users atoms for global state
export const onlineUsersAtom = atom<OnlineUsers>(new Map());
onlineUsersAtom.debugLabel = 'onlineUsersAtom';

// API client atoms for global state
export const apiClientAtom = atom({
  fn: () => {
    const client = axios.create({
      baseURL:
        import.meta.env.MODE === 'development'
          ? 'http://localhost:5000/api'
          : import.meta.env.VITE_API_URL,
      headers: {
        'Content-Type': 'application/json',
      },
      withCredentials: true,
    });

    // Set up request interceptor
    client.interceptors.request.use(
      (config) => {
        // Add access token to the request headers
        const accessToken = globalStore.get(userAtom)?.accessToken;
        if (accessToken) {
          config.headers.Authorization = `Bearer ${accessToken}`;
        }
        return config;
      },
      (error) => Promise.reject(error)
    );

    // Set up response interceptor
    client.interceptors.response.use(
      (response) => response,
      async (error) => {
        console.log('Axios error', error);
        const originalRequest = error.config;
        if (
          error?.response?.status === 401 &&
          !originalRequest._retry &&
          !originalRequest.url.includes('login') &&
          !originalRequest.url.includes('refresh')
        ) {
          originalRequest._retry = true;
          try {
            // Access token expired, refresh it
            await AuthServices.refreshUser();
            const accessToken = globalStore.get(userAtom)?.accessToken;
            // Update the access token in the original request
            originalRequest.headers.Authorization = `Bearer ${accessToken}`;
            // Retry the failed request
            return axios(originalRequest);
          } catch (error) {
            // Handle refresh token expiration or other errors
            console.error(error);
            return Promise.reject(error);
          }
        }

        if (error?.response?.status === 403) {
          // Logout the user and redirect to the login page
          await AuthServices.logout();
          redirect('/login');
        }
        return Promise.reject(error);
      }
    );
    return client;
  },
});
apiClientAtom.debugLabel = 'apiClientAtom';

// socket atoms for global state
export const socketAtom = atom<Socket | null>(null);
socketAtom.debugLabel = 'socketAtom';

// basket atoms for global state
export const basketAtom = atomWithStorage<Basket>('basket', []);
basketAtom.debugLabel = 'basketAtom';
export const customerAtom = atomWithStorage<
  CustomerWithIncluded | OrderCustomer | null
>('customer', null);
customerAtom.debugLabel = 'customerAtom';
export const customerAddressAtom = atomWithStorage<string | undefined>(
  'customerAddress',
  undefined
);

export const validCustomerAtom = atom(true);
validCustomerAtom.debugLabel = 'validCustomerAtom';
export const validateCustomerAtom = atom(null, (get, set, update) => {
  set(validCustomerAtom, true);
});
export const invalidateCustomerAtom = atom(null, (get, set, update) => {
  set(validCustomerAtom, false);
});

export const validBasketAtom = atom(true);
validBasketAtom.debugLabel = 'validBasketAtom';
export const validateBasketAtom = atom(null, (get, set, update) => {
  set(validBasketAtom, true);
});
export const invalidateBasketAtom = atom(null, (get, set, update) => {
  set(validBasketAtom, false);
});

export const addToBasketAtom = atom(
  null,
  (get, set, update: ProductWithIncluded) => {
    set(basketAtom, (old) => {
      // incase it was wipe from local storage
      if (!old) {
        return [{ ...update, quantity: 1 }];
      }

      if (!old.find((i) => i.id === update.id)) {
        return [...old, { ...update, quantity: 1 }];
      }

      return old;
    });
  }
);
export const removeFromBasketAtom = atom(
  null,
  (get, set, update: ProductWithIncluded) => {
    set(basketAtom, (old) => {
      // wipe from local storage
      if (old.filter((i) => i.id !== update.id).length === 0) {
        return RESET;
      }
      return old.filter((i) => i.id !== update.id);
    });
  }
);
export const updateQuantityBasketAtom = atom(
  null,
  (get, set, update: BasketItem) => {
    set(basketAtom, (old) => {
      return old.map((i) => {
        if (i.id !== update.id) {
          return i;
        } else {
          return { ...i, quantity: update.quantity };
        }
      });
    });
  }
);

export const incrementItemAtom = atom(null, (get, set, update: BasketItem) => {
  set(basketAtom, (old) => {
    return old.map((i) => {
      if (i.id !== update.id) {
        return i;
      } else {
        return { ...i, quantity: i.quantity + 1 };
      }
    });
  });
});

export const decrementItemAtom = atom(null, (get, set, update: BasketItem) => {
  set(basketAtom, (old) => {
    return old.map((i) => {
      if (i.id !== update.id) {
        return i;
      } else {
        return { ...i, quantity: i.quantity - 1 ? i.quantity - 1 : 1 };
      }
    });
  });
});

// settings atoms for global state
export const userSettingsAtom = atomWithStorage<User_Settings>('settings', {
  id: 'settings',
  userId: 'default',
  createdAt: new Date(),
  updatedAt: new Date(),
  nav: 'open',
  productView: 'grid',
  currency: 'GBP',
  unit: 'metric',
  darkMode: false,
  history: null,
});
userSettingsAtom.debugLabel = 'userSettingsAtom';
