import type { AuthedUser } from 'beneluxic-types';

export const getColorFromStatus = (status?: string) => {
  status = status?.toLowerCase();
  switch (status) {
    case 'online':
      return '#00c853';
    case 'busy':
      return '#ff1744';
    case 'away':
      return '#ff9100';
    default:
      return '#9e9e9e';
  }
};

export function userInitials(user: AuthedUser) {
  if (!user.firstName && !user.lastName) return null;

  return `${user.firstName?.[0]}${user.lastName?.[0]}`;
}
