// import "./utils/wdyr";
import React from 'react';
import { createRoot } from 'react-dom/client';
import App from './App';
import './index.css';
import { Provider as JotaiProvider } from 'jotai';
import { globalStore } from './store/jotaiStore';
import '@mantine/core/styles.css';
import '@mantine/dates/styles.css';
import '@mantine/tiptap/styles.css';
import { MantineProvider } from '@mantine/core';
const container = document.getElementById('root')!;
const root = createRoot(container);
root.render(
  <React.StrictMode>
    <JotaiProvider store={globalStore}>
      <MantineProvider
        theme={{
          colors: {
            purple: [
              '#EDEEFA',
              '#DBDDF6',
              '#C9CCF1',
              '#B9BCED',
              '#A8ACE8',
              '#989DE3',
              '#898FDF',
              '#7A81DA',
              '#6C73D6',
              '#5D64D0',
            ],
          },
          primaryColor: 'purple',
          primaryShade: 9,
        }}
      >
        <App />
      </MantineProvider>
    </JotaiProvider>
  </React.StrictMode>
);
