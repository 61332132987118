// import Header from "../components/Header";
import { Provider, useAtom, useAtomValue } from 'jotai';
import { Outlet, useLocation } from 'react-router-dom';
import {
  activityAtom,
  basketStore,
  globalStore,
  lastActivityAtom,
  onlineUsersStore,
  socketAtom,
  userAtom,
  userSettingsAtom,
} from '../store/jotaiStore';
import { Navbar } from './Navbar';
import { ModalsProvider } from '@mantine/modals';
import { lazy, useState } from 'react';
import useSocketEventHandlers from '@hooks/useSocketEventHandler';
import { Toaster } from 'react-hot-toast';
import { useIdleTimer } from 'react-idle-timer';
import Header from './Header';
import { useMantineColorScheme } from '@mantine/core';
const ProductModal = lazy(() => import('@modals/ProductModal'));
const EditCustomerModal = lazy(() => import('@modals/EditCustomerModal'));
const EditBrandModal = lazy(() => import('@modals/EditBrandModal'));
const EditProductModal = lazy(() => import('@modals/EditProductModal'));

export default function Wrapper() {
  const user = useAtomValue(userAtom);
  const { setColorScheme } = useMantineColorScheme();

  globalStore.sub(userSettingsAtom, () => {
    // add dark class to body if dark mode is enabled
    updateDarkMode(globalStore.get(userSettingsAtom).darkMode);
  });
  const [socket] = useAtom(socketAtom);
  useSocketEventHandlers(user);

  const updateDarkMode = (darkMode: boolean) => {
    const html = document.querySelector('html');
    if (html) {
      html.classList.toggle('dark', globalStore.get(userSettingsAtom).darkMode);
      setColorScheme(
        globalStore.get(userSettingsAtom).darkMode === true ? 'dark' : 'light'
      );
    }
  };

  const timeout = import.meta.env.PROD
    ? // 5 minutes
      1000 * 60 * 5
    : // 1 minute
      1000 * 60 * 1;

  const onIdle = () => {
    if (!user) return;
    socket?.emit('user_status', {
      status: 'away',
      userId: user.id,
      lastActivity: new Date(Date.now() - timeout).toISOString(),
    }); // Emit "inactive" to the backend
    onlineUsersStore.set(activityAtom, 'away');
    // new date plus timeout
    onlineUsersStore.set(
      lastActivityAtom,
      new Date(Date.now() - timeout).toISOString()
    );
  };

  const onActive = () => {
    if (!user) return;
    socket?.emit('user_status', {
      status: 'online',
      userId: user.id,
      lastActivity: new Date().toISOString(),
    }); // Emit "active" to the backend
    onlineUsersStore.set(activityAtom, 'online');
    onlineUsersStore.set(lastActivityAtom, new Date().toISOString());
  };

  updateDarkMode(globalStore.get(userSettingsAtom).darkMode);

  useIdleTimer({
    onIdle,
    onActive,
    timeout,
  });

  return (
    <div>
      <ModalsProvider
        modals={{
          productOverview: ProductModal,
          editCustomer: EditCustomerModal,
          // editProduct: EditProductModal,
          editBrand: EditBrandModal,
        }}
      >
        <Toaster />
        <div
          id="main"
          className={`flex h-screen flex-col bg-gray-100 dark:bg-gray-base`}
        >
          {user ? (
            <>
              <Header />
              <div className="flex flex-1 overflow-hidden">
                {user && (
                  <Provider store={basketStore}>
                    <Navbar />
                  </Provider>
                )}
                <div className="content-container">
                  <Outlet />
                </div>
              </div>
            </>
          ) : (
            <Outlet />
          )}
        </div>
      </ModalsProvider>
    </div>
  );
}
