import PulseLoader from 'react-spinners/PulseLoader';

function LoadingSpinner({ title }: { title?: string }) {
  return (
    <div className="flex h-full w-full flex-col items-center justify-center gap-8">
      <div className="text-center text-2xl font-bold">{title}</div>
      <PulseLoader size={50} color={'#c7d2fe '} loading />
    </div>
  );
}

export default LoadingSpinner;
