import { Code } from '@mantine/core';
import { useNavigate, useRouteError } from 'react-router-dom';
import { Button } from './Button';

interface Error {
  name?: string;
  message?: string;
  stack?: string;
}

export default function DefaultFallback(): JSX.Element {
  const error = useRouteError() as Error;
  const navigate = useNavigate();
  return (
    <div className="flex h-full items-center justify-center bg-gray-100">
      <div className="description-box flex-col items-center justify-center gap-6 p-6">
        <div className="flex w-full">
          <h1 className="mx-auto font-heading text-2xl text-red-500">
            {error?.name}
          </h1>
        </div>
        <span className="text-xs">{error?.message}</span>

        <Button variant="secondary" onClick={() => navigate('/')}>
          Go to Home
        </Button>

        {import.meta.env.PROD ? (
          <div className="scroll-bar-thin max-h-[500px] overflow-y-auto">
            <Code className="text-red-500">{error?.stack}</Code>
          </div>
        ) : (
          <div>
            <span className="text-xs text-gray-500">
              This error has been logged to the monitoring system and will be
              investigated by the development team.
            </span>
          </div>
        )}
      </div>
    </div>
  );
}
