import React, { forwardRef } from 'react';
import { cva } from 'class-variance-authority';
import { cn } from '@utils/cn';

const buttonVariants = cva(
  `w-max inline-flex items-center rounded-md border px-2.5 py-1 text-xs font-semibold 
    transition-colors focus:outline-none focus:ring-1 focus:ring-purple-tint-4 leading-3
    data-[state="open"]:ring-1 data-[state="open"]:ring-purple-tint-4 dark:data-[state="open"]:ring-gray-2
     dark:bg-gray-1 dark:border-gray-shade-8 dark:text-white dark:hover:text-purple-tint-6
     dark:data-[state="open"]:bg-gray-base
     `,
  {
    variants: {
      variant: {
        default:
          'border-gray-300 dark:bg-purple bg-purple text-white dark:text-purple-tint-9 hover:bg-purple-shade-1 focus:ring-purple-tint-4 focus:ring-1 focus:ring-offset-0 focus:ring-offset-transparent',
        secondary:
          'bg-gray-100 text-gray-700 border border-gray-300 hover:bg-purple-tint-9 hover:text-purple-tint-1 focus:ring-gray-300 focus:ring-1 focus:ring-offset-0 focus:ring-offset-transparent',
        destructive:
          'text-red-800 border border-gray-300 bg-gray-100 hover:bg-purple-tint-9 hover:text-red-600 focus:ring-gray-300 focus:ring-1 focus:ring-offset-0 focus:ring-offset-transparent dark:border-gray-2 dark:text-red-400 dark:hover:text-red-500 ',
        dashed:
          'border-dashed bg-gray-100 text-gray-700 border-gray-300 hover:bg-purple-tint-9 hover:text-purple-tint-1 focus:ring-gray-300 focus:ring-1 focus:ring-offset-0 focus:ring-offset-transparent',
        transparent:
          'border-none bg-transparent data-[state="open"]:ring-none ring-none hover:text-purple-tint-1 hover:bg-purple-tint-9',
        disabled:
          'border-gray-300 bg-gray-100 text-gray-400 cursor-not-allowed',
        outline:
          'border-gray-300 dark:bg-transparent bg-transparent text-gray-700 dark:hover:bg-gray-base hover:bg-purple-tint-9 hover:text-purple-tint-1 focus:ring-gray-300 focus:ring-1 focus:ring-offset-0 focus:ring-offset-transparent',
      },
      size: {
        sm: 'text-xs px-2 py-1 leading-tight h-[28px]',
        md: 'text-sm px-2.5 py-1.5 h-[36px]',
        lg: 'text-base px-3 py-2 h-[42px]',
      },
    },
    defaultVariants: {
      variant: 'default',
      size: 'md', // Default size
    },
  }
);

type ButtonProps = {
  className?: string;
  variant?:
    | 'default'
    | 'secondary'
    | 'destructive'
    | 'outline'
    | 'dashed'
    | 'transparent'
    | 'disabled';
  size?: 'sm' | 'md' | 'lg';
  children?: React.ReactNode;
  leftIcon?: JSX.Element; // Left icon
  rightIcon?: JSX.Element; // Right icon
  disabled?: boolean;
} & React.ButtonHTMLAttributes<HTMLButtonElement>;

const Button = forwardRef<HTMLButtonElement, ButtonProps>(
  (
    {
      className,
      variant,
      size = 'md',
      leftIcon,
      rightIcon,
      disabled,
      ...props
    },
    ref
  ) => {
    const margins = {
      sm: { left: 'ml-1', right: 'mr-1' },
      md: { left: 'ml-2', right: 'mr-2' },
      lg: { left: 'ml-3', right: 'mr-3' },
      xl: { left: 'ml-4', right: 'mr-4' },
    };

    if (disabled) {
      variant = disabled ? 'disabled' : variant;
    }

    return (
      <button
        type="button"
        ref={ref}
        className={cn(buttonVariants({ variant, size }), className)}
        {...props}
      >
        {leftIcon && <span className={margins[size].right}>{leftIcon}</span>}
        {props.children}
        {rightIcon && <span className={margins[size].left}>{rightIcon}</span>}
      </button>
    );
  }
);

export { Button, buttonVariants };
