import { Code } from '@mantine/core';
import { X } from '@phosphor-icons/react';
import { FallbackProps } from 'react-error-boundary';
import { Link } from 'react-router-dom';
import { Button } from './Button';

export default function DefaultFallback(
  props: FallbackProps & { buttonText?: string }
) {
  const { error, resetErrorBoundary } = props;
  return (
    <div className="mx-4 my-2 flex h-full items-center justify-center md:mx-8 md:my-4 lg:mx-12 lg:my-6">
      <div className="description-box flex-col items-center justify-center gap-6 p-6">
        <div className="flex w-full">
          <h1 className="mx-auto font-heading text-2xl text-red-500">
            {error?.name}
          </h1>
          {error?.link && error?.linkName && (
            <X
              onClick={resetErrorBoundary}
              className="cursor-pointer bg-gray-100 text-gray-700 hover:text-red-500"
            />
          )}
        </div>
        <span className="text-xs">{error.message}</span>
        {error?.link && error?.linkName ? (
          <Link className="navlink" to={error?.link}>
            {error?.linkName}
          </Link>
        ) : (
          <Button onClick={resetErrorBoundary}>
            {props.buttonText || 'Try again'}
          </Button>
        )}

        {!import.meta.env.PROD ? (
          <div className="scroll-bar-thin max-h-[500px] overflow-y-auto">
            <Code className="text-red-500">{error?.stack}</Code>
          </div>
        ) : (
          <div>
            <span className="text-xs text-gray-500">
              Error details are hidden in production but have been logged to the
              monitoring service.
            </span>
          </div>
        )}
      </div>
    </div>
  );
}
